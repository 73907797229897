import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/index',
    component: () => import("@/views/home/index.vue"),
    children: [{
      path: 'index',
      name: 'index',
      component: () => import("@/views/home/index/index.vue"),
      meta: {
        title: '康圣友医-首页'
      },
    }, {
      path: 'about',
      name: 'about',
      component: () => import("@/views/home/about/index.vue"),
      meta: {
        title: '康圣友医-关于康圣友医'
      },
    }, {
      path: 'dynamic',
      name: 'dynamic',
      component: () => import("@/views/home/dynamic/index.vue"),
      meta: {
        title: '康圣友医-公司动态'
      },
    }, {
      path: 'dynamic/doctor',
      name: 'dynamicDoctor',
      component: () => import("@/views/home/dynamic/dynamicDoctor.vue"),
      meta: {
        title: '康圣友医-公司动态-医生端小程序'
      },
    }, {
      path: 'dynamic/patient',
      name: 'dynamicPatient',
      component: () => import("@/views/home/dynamic/dynamicPatient.vue"),
      meta: {
        title: '康圣友医-公司动态-患者端小程序'
      },
    }, {
      path: 'dynamic/internet',
      name: 'dynamicInternet',
      component: () => import("@/views/home/dynamic/dynamicInternet.vue"),
      meta: {
        title: '康圣友医-公司动态-康圣环球开启精准诊疗新篇章'
      },
    }
    ]
  }
]

const router = createRouter({
  // hash模式的路由
  history: createWebHashHistory(),
  routes
})

// 路由拦截，形成进度条
router.beforeEach((to, from, next) => {
  // 标题
  document.title = String(to.meta.title || '');
  next()
})
router.afterEach(() => {
  // 置顶
  window.scrollTo(0, 0)
})

export default router
